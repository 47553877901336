<template>
	<div data-route>
		<page-header
			heading="Admin"
		/>
		<div data-element="main">
			<list-view :list="list" />
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isAdminOnly": true
		}
	}
</route>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import ListView    from '@/components/ui/views/listView/ListView';

	export default {
		metaInfo: {
			title: 'Admin'
		},
		components: {
			PageHeader,
			ListView
		},
		data: () => ({
			list: [
				{
					text: 'Users',
					route: '/admin/users'
				},
				{
					text: 'Courses',
					route: '/admin/courses'
				},
				{
					text: 'Knowledgebase',
					route: '/admin/knowledgebase'
				},
				{
					text: 'Focuses',
					route: '/admin/focuses'
				},
				{
					text: 'Keywords',
					route: '/admin/keywords'
				},
				{
					text: 'Approval queue',
					route: '/admin/queue'
				}
			]
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		}
	};

</script>
